(<template>
  <form-template :title="title"
                 :description="description"
                 :progress="progressActive">
    <div class="ent-settings">
      <template v-for="step in enterpriseSettingsSteps">
        <enterprise-settings-step v-if="step.showStep"
                                  :key="step.title"
                                  :title="step.title"
                                  :btn-text="step.btnText"
                                  :btn-disabled="step.btnDisabled"
                                  :class="step.classList"
                                  class="ent-settings__step"
                                  @onsubmit="step.submitHandler">
          <component :is="step.component"
                     :full-form="!showChoiceBlock" />
        </enterprise-settings-step>
      </template>
    </div>
  </form-template>
</template>)

<script>
  import {mapGetters, mapState, mapMutations, mapActions} from 'vuex';
  import WizardFormTemplate from '@/components/wizard_components/WizardFormTemplate';
  import ChooseEnterprise from '@/components/wizard_components/enterprise/ChooseEnterprise';
  import CreateEnterprise from '@/components/wizard_components/enterprise/CreateEnterprise';
  import EnterpriseSettingsStep from '@/components/wizard_components/enterprise/EnterpriseSettingsStep';

  export default {
    components: {
      'enterprise-settings-step': EnterpriseSettingsStep,
      'choose-enterprise': ChooseEnterprise,
      'create-enterprise': CreateEnterprise,
      'form-template': WizardFormTemplate
    },
    data() {
      return {
        progressActive: false
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userUid'
      ]),
      ...mapState('UserInfoStore', {
        isWhitelistEmail: ({mainUserInfo}) => mainUserInfo.emailWhitelisted
      }),
      ...mapState('TTWizardStore', {
        logoFile: (state) => state.stepsData.enterprise.logoFile,
        logoRotation: (state) => state.stepsData.enterprise.logoRotation,
        country: (state) => state.stepsData.address.country,
        countryErrors: (state) => state.errors.country.errors,
        countryErrorsName: (state) => state.errors.country.name,
        orgNumber: (state) => state.stepsData.enterprise.orgNumber,
        orgNumberErrors: (state) => state.errors.orgNumber.errors,
        orgNumberErrorsName: (state) => state.errors.orgNumber.name,
        orgName: (state) => state.stepsData.enterprise.orgName,
        orgNameErrors: (state) => state.errors.orgName.errors,
        orgNameErrorsName: (state) => state.errors.orgName.name,
        terms: (state) => state.stepsData.enterprise.terms,
        termsErrors: (state) => state.errors.terms.errors,
        termsErrorsName: (state) => state.errors.terms.name,
        // add member to existing enterprise
        enterpriseId: (state) => state.stepsData.enterprise.enterpriseId,
        enterpriseErrors: (state) => state.errors.enterprise.errors,
        enterpriseErrorsName: (state) => state.errors.enterprise.name
      }),
      chooseEnterpriseTitle() { return this.$gettext('Choose an enterprise account'); },
      createEnterpriseTitle() { return this.$gettext('Enter the enterprise information'); },
      chooseEnterpriseDescription() {
        return this.$gettext('Your email address matches one or more enterprises that are registered on our platform please add yourself to the right enterprise.');
      },
      title() { return this.isWhitelistEmail ? this.chooseEnterpriseTitle : this.createEnterpriseTitle; },
      description() { return this.isWhitelistEmail ? this.chooseEnterpriseDescription : ''; },
      showChoiceBlock() { return this.isWhitelistEmail; },
      enterpriseSettingsSteps() {
        return [
          {
            component: 'choose-enterprise',
            showStep: this.showChoiceBlock,
            title: this.$gettext('Add me to an existing enterprise account'),
            btnText: this.$gettext('Add me'),
            btnDisabled: this.disableAddMemberBtn,
            classList: 'ent-settings__step--as-variant',
            submitHandler: this.addEnterpriseMember
          },
          {
            component: 'create-enterprise',
            showStep: !this.showChoiceBlock,
            title: '',
            btnText: this.$gettext('Create account'),
            btnDisabled: this.disableCreateBtn,
            classList: this.showChoiceBlock ? 'ent-settings__step--as-variant' : '',
            submitHandler: this.sendData
          }
        ];
      },
      isNorway() { return this.country === 'Norway'; },
      isNotNorwayCompany() { return this.country && !this.isNorway; },
      disableAddMemberBtn() { return !this.enterpriseId; },
      disableCreateBtn() {
        return (this.isNotNorwayCompany && !this.orgName.trim())
          || !this.terms
          || !this.country
          || !this.orgNumber.trim();
      }
    },
    methods: {
      ...mapMutations('TTWizardStore', [
        'setError',
        'removeErrors'
      ]),
      ...mapActions({
        addExistingEmployee: 'EnterpriseStore/addExistingEmployee',
        createEnterprise: 'TTEnterpriseInfoStore/createEnterprise'
      }),
      isAnyError() {
        this.removeErrors();
        let indicator = false;

        if (!this.country.trim()) {
          this.setError({
            fieldName: 'country',
            errorText: this.$gettext('Country is required.')
          });
          indicator = true;
        }
        if (!this.orgNumber.trim()) {
          this.setError({
            fieldName: 'orgNumber',
            errorText: this.$gettext('Org. number can\'t be blank')
          });
          indicator = true;
        }
        if (!this.orgName.trim() && this.isNotNorwayCompany) {
          this.setError({
            fieldName: 'orgName',
            errorText: this.$gettext('Company name can\'t be blank')
          });
          indicator = true;
        }
        if (!this.terms) {
          this.setError({
            fieldName: 'terms',
            errorText: ''
          });
          indicator = true;
        }

        this.$store.commit('ErrorsStore/setError', {name: this.countryErrorsName, errors: this.countryErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.orgNumberErrorsName, errors: this.orgNumberErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.orgNameErrorsName, errors: this.orgNameErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.termsErrorsName, errors: this.termsErrors});

        return indicator;
      },
      isAnyCreateMemberError() {
        let indicator = false;
        this.removeErrors();

        if (!this.enterpriseId) {
          this.setError({
            fieldName: 'enterprise',
            errorText: this.$gettext('Enterprise account is required field')
          });
          indicator = true;
        }
        this.$store.commit('ErrorsStore/setError', {name: this.enterpriseErrorsName, errors: this.enterpriseErrors});

        return indicator;
      },
      addEnterpriseMember() {
        if (!this.isAnyCreateMemberError()) {
          this.progressActive = true;
          this.addExistingEmployee({entId: this.enterpriseId, userId: this.userUid}).then(() => {
            this.$store.dispatch('UserInfoStore/fetchUserInfo', this.userUid).then(() => {
              this.progressActive = false;
              this.nextStepForChosenEnterprise();
            });
          });
        }
      },
      sendData() {
        if (!this.isAnyError()) {
          this.progressActive = true;

          const form = new FormData();

          form.append('enterprise[org_number]', this.orgNumber.trim());
          form.append('enterprise[address][country]', this.country);
          if (this.orgName && this.isNotNorwayCompany) {
            form.append('enterprise[name]', this.orgName.trim());
          }
          if (this.logoFile) {
            form.append('enterprise[logo]', this.logoFile);
          }
          if (this.logoRotation) {
            form.append('enterprise[image_rotation]', this.logoRotation);
          }

          this.createEnterprise(form).then(() => {
            this.$store.dispatch('UserInfoStore/fetchUserInfo', this.userUid).then(() => {
              this.progressActive = false;
              this.nextStepForNewEnterprise();
            });
          }).catch((error) => {
            const errorData = error.data;
            this.progressActive = false;
            if (errorData?.errors
              && errorData.errors?.org_number.includes('Organisation number is invalid')) {
              this.setError({
                fieldName: 'orgNumber',
                errorText: this.$gettext('Organisation number is not valid')
              });
              this.$store.commit('ErrorsStore/setError', {name: this.orgNumberErrorsName, errors: this.orgNumberErrors});
            } else if (error?.status == 422) {
              this.$store.commit('ModalStore/setModal', {
                component: 'contact-admin',
                data: {
                  title: ''
                }
              });
            }
          });
        }
      },
      goToHomePage() {
        this.$router.push(this.$makeRoute({name: 'BuyerHome'}));
      },
      goToPaymentStep() {
        this.$router.push(this.$makeRoute({name: 'BuyerEnterprisePayment'}));
      },
      nextStepForNewEnterprise() {
        this.isNotNorwayCompany ? this.goToHomePage() : this.goToPaymentStep();
      },
      nextStepForChosenEnterprise() {
        this.$router.push(this.$makeRoute({name: 'BuyerHome'}));
      }
    }
  };
</script>

<style scoped>
  .ent-settings {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .ent-settings__step {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .ent-settings__step--as-variant {
    width: 350px;
    padding: 20px 25px;
  }

  @media (max-width: 650px) {
    .ent-settings__step--as-variant {
      margin: 0 auto;
      padding: 20px;
    }
  }
</style>
