(<template>
  <enterprise-settings />
</template>)

<script>
  import WizardEnterpriseSettings from '@/components/wizard_components/WizardEnterpriseSettings';

  export default {
    asyncData({store}) {
      let promisesArr = [];
      if (!store.state.EnterpriseStore.enterprises) {
        promisesArr = [...promisesArr, store.dispatch('EnterpriseStore/getEnterprises')];
      }
      return promisesArr ? Promise.all(promisesArr) : promisesArr;
    },
    components: {
      'enterprise-settings': WizardEnterpriseSettings
    },
    beforeRouteLeave(to, from, next) {
      this.$store.commit('EnterpriseStore/removeEnterprises');
      next();
    }
  };
</script>
