<template>
  <div class="choose-enterprise">
    <sk-select :items-list="enterprisesList"
               :description="enterpriseText"
               :default-value="enterpriseDefaultText"
               :preselected-value="enterpriseId"
               :validation-name="enterpriseErrorsName"
               class="choose-enterprise__selector"
               @csvaluechanged="setEnterprise" />
  </div>
</template>

<script>
  import {mapState, mapMutations} from 'vuex';

  export default {
    computed: {
      ...mapState('TTWizardStore', {
        enterpriseId: (state) => state.stepsData.enterprise.enterpriseId,
        enterpriseErrorsName: (state) => state.errors.enterprise.name
      }),
      ...mapState('EnterpriseStore', {
        enterprises: (state) => state.enterprises || []
      }),
      enterpriseText() { this.$gettextInterpolate(this.$gettext('Registered enterprise accounts on %{domain}'), {domain: this.$getString('domainData', 'platformName')}); },
      enterpriseDefaultText() { return this.$gettext('Choose'); },
      enterprisesList() {
        return this.enterprises.map((enterprise) => {
          return {id: enterprise.id, name: `${enterprise.name} - ${enterprise.ownerName}`};
        });
      }
    },
    methods: {
      ...mapMutations('TTWizardStore', ['setEnterprise'])
    }
  };
</script>

<style scoped>
  .choose-enterprise__selector {
    margin-bottom: 15px;
    text-align: left;
  }

  @media (max-width: 650px) {
    .choose-enterprise {
      flex-direction: column;
    }
  }
</style>
